import { mapActions, mapState } from 'vuex'
import { myMixin } from '../../../../mixins'

export default{
  mixins: [myMixin],
  data: () => ({
    teste: false,
    renderComponent: true,
    interval: 30,
    eventSelectedOpen: {},
    searchAmbience: null,
    ambience: {},
    renderComponentTitle: true,
    titleDate: '',
    progress: false,
    drawer: true,
    events: [],
    selectedOpenNew: false,
    showSearch: false,
    startShowEvent: false,
    showDelete: false,
    loadingDelete: false,
    start: '2024-01-01',
    items: [],
    focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        '4day': '4 Dias',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],

    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,

    message: '',
    error: false,
      
  }),
    mounted(){
      
      if(this.$refs.calendar){
        this.$refs.calendar.checkChange();
      }

      this.forceRerenderTitle();

    },

    created(){

      let periodo = this.acesso.periodo;
      let time = this.getDateTime();
      this.start = time.date < periodo.start ? periodo.start : time.date;
      this.forceRerenderTitle();
     

    },

    watch: {

      selectedOpen(val){

        if(!val && !this.startShowEvent){
          this.closeEvent();
        }

      },

    },

    computed: {
      ...mapState('auth', ['acesso', 'user']),

      allowEditForm (){

        let event = this.selectedEvent;
        
        if(!event){
          return false;
        }
        
        let type = event.allocation_type_id;
        let types = [2,3];

        if(this.user.is_internal){
          return (type == 1) ? false : true;
        }else{
          return (event.user && event.user.id == this.user.id && types.includes(event.allocation_type_id));
        }

      },

      formatedTitle(){

        if(this.ambience && this.ambience.slug_full){
          return this.$route.meta.title + ' - ' + this.ambience.slug_full;
        }else{
          return this.$route.meta.title;
        }

      },

    },
    
    methods: {
      ...mapActions('roomAllocation', ['ActionIndexAllocationsAll', 'ActionDeleteAllocation', 'ActionUpdateAllocation']),

      selectAmbienceSearch(event){

        this.start = event.start;
        this.selectAmbience(event.ambience)

      },
      
      selectAmbience(ambience){
        this.ambience = ambience;
        this.getData();
      },
      
      getData(progress = true){
      
        if(!this.ambience){
          return;
        }
        
        this.progress = progress;
        this.selectedOpenNew = false;
        this.items = [];

        var payload = {
          ambience_id: this.ambience.id,
        };

        this.ActionIndexAllocationsAll(payload)
            .then((res) => {
                this.items = res.data;
                this.setEvents();
            })
            .finally(() => {
                this.progress = false;
                this.closeEvent();
            });
      },

      deleteItem()
        {
            
            this.loadingDelete = true;

            this.ActionDeleteAllocation({id: this.selectedEvent.id})
                .then((res) => {
                    this.message = res.message;
                    this.getData();
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                    this.loadingDelete = false;
                    this.showDelete = false;
                    
                });
        
        },

      setEvents(){

        this.events = [];

        this.items.forEach(item => {
          
          this.events.push({
            id:                 item.id,
            allocation_type_id: item.allocation_type_id,
            organizer_user_id:  item.organizer_user_id,
            name:               item.title,
            title:              item.title,
            organizer:          item.organizer,
            start:              Date.parse(item.start),
            end:                Date.parse(item.end),
            start_date:         item.start_date,
            end_date:           item.end_date,
            hour_start:         item.hour_start,
            hour_end:           item.hour_end,
            details:            item.details,
            color:              item.color,
            resources:          item.resources,
            timed:              item.timed,
            user:               item.user,
            userApprover:       item.userApprover,
            userOrganizer:      item.userOrganizer,
            type:               item.type,
            days:               item.days,
          });
          

        });

        this.forceRerender();
        this.updateTitle();

      },

      forceRerender () {
        
        this.renderComponent = false;

        this.$nextTick(() => {
          this.renderComponent = true;
        });

      },

      forceRerenderTitle () {
        
        this.renderComponentTitle = false;

        this.$nextTick(() => {
          this.renderComponentTitle = true;
        });

      },

      updateTitle(){
        this.titleDate = this.$refs.calendar.title;
      },

      openNew(){

        Object.assign(this.eventSelectedOpen, this.selectedEvent);

        this.selectedOpenNew = true;

      },

      viewDay ({ date }) {
        this.focus = date;
        this.type = 'day';
      },

      addEvent(time = null){
        
        if(!time){
          time = {
            start_date: this.focus,
            end_date:   this.focus,
            hour_start: null,
            hour_end:   null,
          }
        }
        
        this.eventSelectedOpen            = {};
        this.eventSelectedOpen.start_date = time.start_date;
        this.eventSelectedOpen.end_date   = time.end_date;
        this.eventSelectedOpen.hour_start = time.hour_start;
        this.eventSelectedOpen.hour_end   = time.hour_end;
        this.selectedOpenNew              = true;
      },

      setToday () {
        this.focus = '';
      },
      prev () {
        this.$refs.calendar.prev();
      },
      next () {
        this.$refs.calendar.next();
      },
      
      showEvent ({ nativeEvent, event }) {
        
        if(this.dragStart){
          this.closeEvent();
          return ;
        }

        this.startShowEvent = true;
        
        const open = () => {
          this.selectedEvent = event;
          this.selectedElement = nativeEvent.target;
          setTimeout(() => {
            this.selectedOpen = true;
            this.startShowEvent = false;
          }, 10)
        }

        if (this.selectedOpen) {
          this.closeEvent();
        } else {
          this.selectedOpen = true
          open()
        }

        nativeEvent.stopPropagation();
     

      },

      closeEvent(){
        this.selectedEvent = null;
        this.selectedElement = null;
        this.selectedOpen = false;
        this.startShowEvent = false;
      },

      startDrag ({ event, timed }) {
        
        if (event && timed) {
          this.dragEvent = event
          this.dragTime = null
          this.extendOriginal = null
        }
      },

      startTime (tms) {
        
        if(!this.ambience || !this.ambience.id){
          return;
        }

        if(tms.nativeEvent.button !== 0){
          return;
        }

        const mouse = this.toTime(tms);

        if (this.dragEvent && this.dragTime === null) {
          
          if(!this.allowDragDrop(this.dragEvent)){
            this.dragEvent = null;
            return;
          }

          const start = this.dragEvent.start
          this.dragTime = mouse - start

        } else if (!this.selectedOpen) {
          
          let start = this.roundTime(mouse);
          let end = new Date(start + (this.interval * 60000));
          let dateTimeStart = this.getDateTime(start);
          let dateTimeEnd = this.getDateTime(end);

          let time = {
            start_date: tms.date,
            end_date:   tms.date,
            hour_start: dateTimeStart.time,
            hour_end:   dateTimeEnd.time,
          };

          this.addEvent(time);
          
          // this.createStart = this.roundTime(mouse)
          // this.createEvent = {
          //   name: `Event #${this.events.length}`,
          //   color: this.rndElement(this.colors),
          //   start: this.createStart,
          //   end: this.createStart,
          //   timed: true,
          // }

          // this.events.push(this.createEvent)

        }

      },

      allowDragDrop(event){

        if(!event){
          return false;
        }
        
        let type = event.allocation_type_id;

        if((type == 2 || type == 3) && (event.start_date == event.end_date)){
          return true;
        }else{
          return false;
        }

      },

      extendBottom (event) {
        
        this.createEvent = event
        this.createStart = event.start
        this.extendOriginal = event.end

      },
      mouseMove (tms) {
        
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime !== null) {
          const start = this.dragEvent.start
          const end = this.dragEvent.end
          const duration = end - start
          const newStartTime = mouse - this.dragTime
          const newStart = this.roundTime(newStartTime)
          const newEnd = newStart + duration

          this.dragEvent.start = newStart
          this.dragEvent.end = newEnd
        } else if (this.createEvent && this.createStart !== null) {
          const mouseRounded = this.roundTime(mouse, false)
          const min = Math.min(mouseRounded, this.createStart)
          const max = Math.max(mouseRounded, this.createStart)

          this.createEvent.start = min
          this.createEvent.end = max
        }
      },

      endDrag () {
        
        let event = null;

        if(this.dragEvent){
          event = this.dragEvent;
        }else if(this.createEvent){
          event = this.createEvent;
        }

        this.dragTime = null;
        this.dragEvent = null;
        this.createEvent = null;
        this.createStart = null;
        this.extendOriginal = null;

        if(event && this.allowDragDrop(event)){
          
          let start = this.getDateTime(event.start);
          let end = this.getDateTime(event.end);

          if(event.start_date != start.date 
              || event.end_date != end.date 
              || event.hour_start != start.time 
              || event.hour_end != end.time)
              {
                event.start_date  = start.date;
                event.end_date    = end.date;
                event.hour_start  = start.time;
                event.hour_end    = end.time;
      
                this.updateEvent(event);

              }
        }

      },

      updateEvent(event){

        event.ambience_id = this.ambience.id;
        this.message = '';
        this.error = false;
        
        
        this.ActionUpdateAllocation(event)
                .then((res) => {
                  
                })
                .catch((error) =>{
                  this.message = error.message;
                  this.error = true;
                })
                .finally(() => {
                  this.getData(false);
                })

      },

      cancelDrag () {
        
        if (this.createEvent) {
          if (this.extendOriginal) {
            this.createEvent.end = this.extendOriginal
          } else {
            const i = this.events.indexOf(this.createEvent)
            if (i !== -1) {
              this.events.splice(i, 1)
            }
          }
        }

        this.createEvent = null
        this.createStart = null
        this.dragTime = null
        this.dragEvent = null
      },
      roundTime (time, down = true) {
        const roundTo = this.interval; // minutes
        const roundDownTime = roundTo * 60 * 1000;

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },

      toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
      },
      getEventColor (event) {
        
        if(!event || !event.color){
          return 'blue';
        }
        
        const rgb = parseInt(event.color.substring(1), 16)
        const r = (rgb >> 16) & 0xFF
        const g = (rgb >> 8) & 0xFF
        const b = (rgb >> 0) & 0xFF

        return event === this.dragEvent
          ? `rgba(${r}, ${g}, ${b}, 0.7)`
          : event === this.createEvent
            ? `rgba(${r}, ${g}, ${b}, 0.7)`
            : event.color
      },
      getEvents ({ start, end }) {
        
        return;
        
        const events = []

        const min = new Date(`${start.date}T00:00:00`).getTime()
        const max = new Date(`${end.date}T23:59:59`).getTime()
        const days = (max - min) / 86400000
        const eventCount = this.rnd(days, days + 20)

        for (let i = 0; i < eventCount; i++) {
          const timed = this.rnd(0, 3) !== 0
          const firstTimestamp = this.rnd(min, max)
          const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000
          const start = firstTimestamp - (firstTimestamp % 900000)
          const end = start + secondTimestamp

          events.push({
            name: this.rndElement(this.names),
            color: this.rndElement(this.colors),
            start,
            end,
            timed,
          })
        }

        this.events = events
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      rndElement (arr) {
        return arr[this.rnd(0, arr.length - 1)]
      },


    },
}
